import Vue from 'vue'
import Vuex from 'vuex'
import headerCourses from './store-modules/headerCourses'
import headerDirections from './store-modules/headerDirections'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    headerCourses: headerCourses,
    headerDirections: headerDirections
  }
})
