<template>
  <!-- TODO: Заменить на ДСный, когда создадим компонент в WEBDEV-157272 -->
  <ul class="ui-header-dropdown ui-shadow-8pt">
    <li
      v-for="(link, j) in dropdown"
      :key="j"
      class="ui-header-dropdown__item"
    >
      <a
        :href="link.href"
        target="_blank"
        class="ui-header-dropdown__link f f--16"
        @click="linkClickHandler(link.analytics && link.analytics.title)"
      >
        {{ link.title }}
      </a>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'UiHeaderDropdown',
    props: {
      dropdown: {
        type: Array,
        required: true
      },
      isRoute: {
        type: Boolean,
        default: false
      },
      // используется для аналитики кликов
      clickCallback: {
        type: Function,
        default: null
      }
    },
    methods: {
      linkClickHandler(dropdownItemName) {
        if (typeof this.clickCallback === 'function') {
          this.clickCallback(dropdownItemName)
        }

        this.$emit('link-click', dropdownItemName)
      },
      checkActive(link) {
        return link.toLowerCase() === this.$route.path.toLowerCase()
      }
    }
  }
</script>

<style lang="scss">
  .ui-header#ui-header {
    .ui-header-dropdown {
      @include ulres;

      padding: 16px 12px;
      border-radius: 10px;
      background-color: var(--bg-with-shadow-color);
    }

    .ui-header-dropdown__item {
      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &:nth-child(6) {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;

        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          content: '';
          width: calc(100% - 24px);
          margin-right: 12px;
          margin-left: 12px;
          background: var(--stroke-main-color);
          height: 1px;
        }
      }
    }

    .ui-header-dropdown__link:focus-visible {
      background-color: var(--bg-secondary-color);
    }

    /* stylelint-disable */
    .ui-header-dropdown__link {
      display: inline-block;
      width: 100%;
      padding: 8px 12px;
      border-radius: 8px;
      color: var(--text-main-color);
      white-space: nowrap;

      @include hover {
        background-color: var(--bg-secondary-color);
      }

      &--active {
        background-color: var(--bg-secondary-accent-color);
      }
    }
    /* stylelint-enable */
  }

  .is-safari .ui-header#ui-header .ui-header-dropdown__link:not(.ui-header-dropdown__link--active):focus {
    background-color: var(--bg-secondary-color);
  }
</style>
