export default (makeRequest, $config) => ({
  /**
   * @param data { object } объект с параметрами для получения подсказок
   * @param data.search_query { string } поисковая фраза.
   * @param data.ga_client_id { string } ga id.
   * @param config { object } объект с параметрами для axios
   * @param config.progress { boolean } флаг отвечающий за отображение лоадера на nuxt проектах
   */
  get(data, config) {
    return makeRequest({
      url: `${$config.skillsearchApi}_/skillsearch/api/completion/completion/`,
      method: 'POST',
      data,
      config
    })
  }
})
