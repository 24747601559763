<template>
  <transition name="header-fade">
    <div
      v-escape="close"
      v-bsl="true"
      :style="`--header-menu-desktop-top-offset: ${topOffset}`"
      class="ui-header-desktop-menu"
      @click="close"
    >
      <div
        ref="headerContent"
        class="ui-header-desktop-menu__content ui-container ui-shadow-10pt"
        @click.stop
      >
        <UiSearchDesktop
          :get-suggestions="$api.suggestions.get"
          :user-id="userId"
          placeholder="Профессия или навык"
          size="medium"
          class="ui-header-desktop-menu__search"
          @update="menuSearchHandler($event)"
        />

        <UiHeaderDirections
          :is-route-directions="isRouteDirections"
          :is-dark-mode="isDarkMode"
          class="ui-header-desktop-menu__directions"
          @click-direction="close"
        />

        <UiHeaderCourses
          class="ui-header-desktop-menu__courses"
          @update-focusable-slides="$nextTick(updateFocusTrap)"
        />
      </div>
    </div>
  </transition>
</template>

<script>
  import { activateFocusTrap, deactivateFocusTrap } from '@skillbox/front-box/dist/utils/focus-tab'
  import UiSearchDesktop from '@skillbox/front-box/dist/Blocks/UiSearch/components/UiSearchDesktop/UiSearchDesktop'
  import UiHeaderDirections from './modules/Directions/UiHeaderDirections'
  import UiHeaderCourses from './modules/Courses/UiHeaderCourses'

  export default {
    name: 'UiHeaderDesktopMenu',
    components: {
      UiSearchDesktop,
      UiHeaderDirections,
      UiHeaderCourses
    },
    props: {
      isRouteDirections: {
        type: Boolean,
        default: false
      },
      userId: {
        type: String,
        default: undefined
      },
      isDarkMode: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        topOffset: '150px'
      }
    },
    mounted() {
      this.setOffset()

      this.$nextTick(() => {
        this.activateFocusTrap()
      })
    },
    beforeDestroy() {
      this.$refs.headerContent && deactivateFocusTrap(this.$refs.headerContent)
    },
    methods: {
      activateFocusTrap() {
        this.$refs.headerContent && activateFocusTrap(this.$refs.headerContent)
        this.$refs.headerContent && this.$refs.headerContent.focus({ preventScroll: true })
      },
      updateFocusTrap() {
        this.$refs.headerContent && activateFocusTrap(this.$refs.headerContent)
      },
      setOffset() {
        const OFFSET_FROM_BUTTON = 8
        const toggleButton = document.querySelector('.ui-header__toggle')

        if (toggleButton) {
          this.topOffset = `${Math.ceil(toggleButton.getBoundingClientRect().top + toggleButton.getBoundingClientRect().height + OFFSET_FROM_BUTTON)}px`
        }
      },
      close() {
        this.$emit('close')
      },
      menuSearchHandler(value) {
        this.$analytics && this.$analytics.push('header-search-submit', { search: value })
        window.location.href = `https://skillbox.ru/courses/?search=${encodeURIComponent(value)}&sc=courses`
      }
    }
  }
</script>

<style lang="scss">
  .ui-header#ui-header {
    .ui-header-desktop-menu {
      @include z-index(fixed, header);

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: var(--header-menu-desktop-top-offset);
      overflow: hidden;
      outline: none;
      animation: .3s ease-in-out forwards bg-show;
    }

    .ui-header-desktop-menu__content {
      display: grid;
      grid-template-columns: 474px 542px;
      grid-gap: 18px;
      grid-template-rows: auto 1fr;
      justify-content: space-between;
      padding: 26px;
      border-radius: 20px;
      background-color: var(--bg-main-color);
      opacity: 0;
      animation: .4s ease-in-out .1s forwards header-show;

      &:focus {
        outline: none;
      }
    }

    .ui-header-desktop-menu__wrapper {
      border-radius: 20px;
    }

    .ui-header-desktop-menu__search {
      margin-left: 14px;
      margin-top: 14px;
    }

    .ui-header-desktop-menu__courses {
      margin-top: 22px;
      grid-column: 2/3;
      grid-row: 1/3;
    }

    .ui-header-desktop-menu {
      animation: .3s ease-in-out forwards header-bg-show;
    }

    .ui-header-desktop-menu.header-fade-leave-to {
      background-color: rgba(0, 0, 0, .35);
      animation: .3s ease-in-out .1s forwards header-bg-hide;
    }

    .header-fade-leave-to .ui-header-desktop-menu__content {
      animation: .4s ease-in-out forwards header-hide;
    }

    @keyframes header-show {
      from { opacity: 0; }
      to { opacity: 1; }
    }

    @keyframes header-hide {
      from { opacity: 1; }
      to { opacity: 0; }
    }

    @keyframes header-bg-show {
      from { background-color: rgba(0, 0, 0, 0); }
      to { background-color: rgba(0, 0, 0, .35); }
    }

    @keyframes header-bg-hide {
      from { background-color: rgba(0, 0, 0, .35); }
      to { background-color: rgba(0, 0, 0, 0); }
    }
  }
</style>
