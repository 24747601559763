<template>
  <header
    ref="header"
    :class="{'ui-header--opened': isShowMenu}"
    class="ui-header"
    id="ui-header"
  >
    <div class="ui-header__wrapper">
      <div class="ui-header__content ui-container">
        <UiHeaderLogo
          :is-dark-mode="isDarkMode"
          :logo-is-route="logoIsRoute"
          class="ui-header__logo"
          @click.native="isShowMenu = false"
        />

        <UiHeaderLogin
          v-if="isLgMax && !isHideLogin"
          class="ui-header__login"
          :login="navigation.login"
        />

        <UiHeaderMenuToggle
          v-if="isLgMax"
          :is-active="isShowMenu"
          class="ui-header__menu-toggle"
          @click="toggleMobileMenu"
        />

        <UiHeaderDesktopMenu
          v-if="isShowMenu && $mq === 'xl'"
          :user-id="gaId"
          :is-dark-mode="isDarkMode"
          :is-route-directions="directionsIsRoute"
          @close="isShowMenu = false"
        />

        <UiTab
          v-if="!isLgMax"
          :is-active="isShowMenu"
          :class="{'ui-header__toggle--active': isShowMenu}"
          :is-disabled-on-active="false"
          :is-rotate-media-on-active="true"
          icon="ui-chevron-down"
          media-position="right"
          class="ui-header__toggle"
          @click="showMenu"
        >
          Каталог
        </UiTab>

        <UiHeaderNavBar
          v-show="!isLgMax"
          :navigation="navigation"
          :is-hide-login="isHideLogin"
          class="ui-header__nav"
          @close="isShowMenu = false"
        />
      </div>
    </div>

    <UiHeaderMobileMenu
      v-show="isShowMenu && $mq !== 'xl'"
      :is-visible="isShowMenu && $mq !== 'xl'"
      :navigation="navigation"
      :user-id="gaId"
      :is-dark-mode="isDarkMode"
      :is-route-directions="directionsIsRoute"
      :is-hide-login="isHideLogin"
      @close="isShowMenu = false"
    />
  </header>
</template>

<script>
  import { ClientIds } from '@skillbox/client-ids'
  import routeModes from './header-route-modes'

  import UiHeaderLogo from './components/UiHeaderLogo'
  import UiHeaderLogin from './components/UiHeaderLogin'
  import UiHeaderMenuToggle from './components/nav/mobile/UiHeaderMenuToggle'
  import UiHeaderNavBar from './components/nav/desktop/UiHeaderNavBar'
  import UiHeaderDesktopMenu from './components/Menu/UiHeaderDesktopMenu'
  import UiHeaderMobileMenu from './components/Menu/UiHeaderMobileMenu'

  export default {
    name: 'UiHeader',
    components: {
      UiHeaderDesktopMenu,
      UiHeaderMobileMenu,
      UiHeaderLogo,
      UiHeaderLogin,
      UiHeaderMenuToggle,
      UiHeaderNavBar
    },
    props: {
      isDarkMode: {
        type: Boolean,
        default: false
      },
      project: {
        type: String,
        default: 'other'
      },
      isHideLogin: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        gaId: null,
        isShowMenu: false,
        logoIsRoute: Boolean(routeModes[this.project].logo),
        directionsIsRoute: Boolean(routeModes[this.project].directions),
        navigation: {
          links: [
            {
              slug: 'about-skillbox',
              title: 'О Skillbox',
              isRoute: Boolean(routeModes[this.project].about),
              excludeUnDescribedRoutes: true,
              analytics: {
                title: 'About',
                legacyPush: 'main_company'
              },
              dropdown: [
                {
                  href: 'https://skillbox.ru/company/',
                  to: '/company/',
                  title: 'О нас',
                  analytics: {
                    title: 'Company'
                  }
                },
                {
                  href: 'https://events.skillbox.ru/researches',
                  to: null,
                  title: 'Результаты',
                  analytics: {
                    title: 'Results'
                  }
                },
                {
                  href: 'https://skillbox.ru/otzyvy/',
                  to: '/otzyvy/',
                  title: 'Отзывы',
                  analytics: {
                    title: 'Otzyvy'
                  }
                },
                {
                  href: 'https://skillbox.ru/contacts/',
                  to: '/contacts/',
                  title: 'Контакты',
                  analytics: {
                    title: 'Contacts'
                  }
                },
                {
                  href: 'https://skillbox.ru/our-great-team/',
                  to: '/our-great-team/',
                  title: 'Вакансии',
                  analytics: {
                    title: 'Jobs'
                  }
                },
                {
                  href: 'https://skillbox.ru/platform/',
                  to: '/platform/',
                  title: 'О платформе',
                  splitLine: true,
                  analytics: {
                    title: 'Platform'
                  }
                },
                {
                  href: 'https://skillbox.ru/career/',
                  to: '/career/',
                  title: 'Центр карьеры',
                  analytics: {
                    title: 'Career'
                  }
                },
                {
                  href: 'https://skillbox.ru/teachers/',
                  to: '/teachers/',
                  title: 'Школа кураторов',
                  analytics: {
                    title: 'Teachers'
                  }
                },
                {
                  href: 'https://go.skillbox.ru/referral?utm_source=main_about_sb',
                  to: null,
                  title: 'Скидки для друзей',
                  analytics: {
                    title: 'Referral'
                  }
                },
                {
                  href: 'https://community.skillbox.ru/',
                  to: null,
                  title: 'Сообщество Skillbox',
                  analytics: {
                    title: 'Community'
                  }
                }
              ]
            },
            {
              isRoute: false,
              href: 'https://kids.skillbox.ru/?utm_source=skillbox&utm_medium=site&utm_campaign=skillbox_kids&utm_content=header_tab',
              to: null,
              title: 'Для детей',
              picture: {
                sm: {
                  avif: {
                    '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/header/for-kids-sm@2x.avif'
                  },
                  webp: {
                    '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/header/for-kids-sm@2x.webp'
                  },
                  img: {
                    '1x': 'https://cdn.skillbox.pro/wbd-front/skillbox-static/header/for-kids-sm@2x.png'
                  }
                }
              },
              analytics: {
                title: 'For kids'
              }
            },
            {
              slug: 'live',
              isRoute: Boolean(routeModes[this.project].webinars),
              title: 'Вебинары',
              excludeUnDescribedRoutes: false,
              datalayerEvent: 'main_webinars',
              customAnalyticButtonName: 'Webinars',
              analytics: {
                title: 'Webinars',
                legacyPush: 'main_webinars'
              },
              dropdown: [
                {
                  href: 'https://live.skillbox.ru/',
                  to: '/',
                  title: 'Все вебинары',
                  analytics: {
                    title: 'webinars'
                  }
                },
                {
                  href: 'https://live.skillbox.ru/playlists/',
                  to: '/playlists/',
                  title: 'Плейлисты',
                  analytics: {
                    title: 'Playlists'
                  }
                }
              ]
            },
            {
              isRoute: false,
              href: 'https://skillbox.ru/media/',
              to: null,
              title: 'Медиа',
              analytics: {
                title: 'Magazine'
              }
            }
          ],
          login: {
            isRoute: false,
            href: 'https://go.skillbox.ru/',
            title: 'Войти',
            analytics: {
              title: 'Authorization',
              legacyPush: 'main_login'
            }
          },
          allCoursesMobile: {
            title: 'Каталог',
            slug: 'all-courses',
            analytics: {
              title: 'All courses'
            }
          }
        }
      }
    },
    async beforeMount() {
      const logger = this.$logger ? this.$logger : { log: console.error }
      const clientIds = new ClientIds(logger)

      this.gaId = await clientIds.getGaId()
    },
    methods: {
      isSafari() {
        return document.querySelector('body').classList.contains('is-safari')
      },
      toggleMobileMenu() {
        this.isShowMenu = !this.isShowMenu

        // safari: scrollTo(0,0) не приводит к скроллу, и значения, например, scrollY будут 0
        // 100 магическое значение (потому что нет разницы насколько в минус скроллить),
        // но -1 не поможет, когда панелька сверху. Правильное значение кроется где-то в области 55-60.
        this.isSafari()
          ? window.scrollTo(0, -100)
          : window.scrollTo(0, 0)

        this.$analytics && this.$analytics.push('header-toggle-click', { status: this.isShowMenu ? 'opened' : 'closed' })
      },
      showMenu() {
        this.isShowMenu = !this.isShowMenu
        this.$analytics && this.$analytics.push('header-all-courses-click')

        window.scrollTo(0, 0) // тут десктопный сафари нормально скроллит

        // TODO отрефакторить
        this.$analytics && this.$analytics.push('header-legacy', { event: 'main_popup_all' })
      }
    }
  }
</script>

<style lang="scss">
  .ui-header#ui-header {
    @include media(xl) {
      position: relative;
      z-index: 50;
      transition: z-index 400ms;
    }

    &--opened {
      transition: z-index 0ms;
      z-index: 51;
    }
  }

  .ui-header__wrapper {
    color: var(--text-main-color);
    background-color: var(--bg-main-color);
  }

  .ui-header--opened .ui-header__wrapper {
    @include z-index(relative, popup);
  }

  .ui-header__content {
    display: flex;
    align-items: center;
    min-height: 74px;

    @include media(md) {
      min-height: 96px;
    }
  }

  .ui-header__logo {
    @include media(lg-max) {
      margin-right: auto;
    }
  }

  .ui-header__login {
    margin-right: 4px;

    @include media(xl) {
      display: none;
    }
  }

  .ui-header__menu-toggle {
    margin-right: -8px;

    &:not(:disabled) {
      @include hover {
        color: inherit;
        background-color: inherit;
      }
    }
  }

  .ui-header#ui-header {
    .ui-header__nav {
      @include media(lg-max) {
        display: none;
      }

      @include media(lg) {
        margin-left: auto;
      }
    }

    .ui-header__toggle {
      @include z-index(relative, header);

      @include media(lg-max) {
        display: none;
      }

      @include media(xl) {
        margin-left: 157px;
      }
    }
  }
</style>
