<template>
  <UiIconButton
    type="button"
    theme="additional"
    shape="circle"
    :icon="isActive ? 'ui-close' : 'ui-burger'"
    :aria-label="isActive ? 'Закрыть меню' : 'Открыть меню'"
    v-on="$listeners"
  />
</template>

<script>
  import UiIconButton from '@skillbox/front-box/dist/Buttons/UiIconButton/UiIconButton'

  export default {
    name: 'UiHeaderMenuToggle',
    components: {
      UiIconButton
    },
    props: {
      isActive: {
        type: Boolean,
        required: true
      }
    }
  }
</script>
