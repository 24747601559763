export default (http) => {
  return ({ url = '/', method = 'GET', params = {}, data = {}, headers = {}, config = {} }) => {
    return http({
      url,
      method,
      data,
      headers,
      params,
      ...config
    })
  }
}
