<template>
  <div
    tabindex="-1"
    :class="{'ui-header-nav-item--opened': isOpened}"
    class="ui-header-nav-item"
    @mouseenter="dropdownToggleByMouse(true)"
    @mouseleave="dropdownToggleByMouse(false)"
  >
    <UiTab
      v-if="item.dropdown"
      theme="additional"
      size="medium"
      icon="ui-chevron-down"
      media-position="right"
      :is-interacted-from-outside="isOpened"
      :is-always-default-cursor="true"
      :is-active="checkActive()"
      class="ui-header-nav-item__tab ui-header-nav-item__tab--dropdown"
      @click="dropdownToggleByKeyboard"
    >
      <span class="sr-only">
        Открыть меню:
      </span>

      {{ item.title }}
    </UiTab>

    <template v-else>
      <UiTab
        v-if="item.isRoute && item.to"
        :to="item.to"
        theme="additional"
        size="medium"
        @click.native="firstLevelAnalytics"
      >
        {{ item.title }}
      </UiTab>

      <UiTab
        v-else
        :href="item.href"
        :picture="item.picture"
        target="_blank"
        theme="additional"
        size="medium"
        @click.native="firstLevelAnalytics"
      >
        {{ item.title }}
      </UiTab>
    </template>

    <transition name="slide-fade">
      <UiHeaderDropdown
        v-if="item.dropdown"
        v-show="isOpened"
        :dropdown="item.dropdown"
        :is-route="item.isRoute"
        :click-callback="innerLinkClickHandler"
        class="ui-header-nav-item__dropdown"
      />
    </transition>
  </div>
</template>

<script>
  import { activateFocusTrap, deactivateFocusTrap } from '@skillbox/front-box/dist/utils/focus-tab'
  import UiHeaderDropdown from './UiHeaderDropdown'

  export default {
    name: 'UiHeaderNavItem',
    components: {
      UiHeaderDropdown
    },
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        isOpened: false
      }
    },
    methods: {
      dropdownToggleByMouse(state) {
        this.isOpened = state

        const isNeedAnalyticsPush = this.item.dropdown && state
        isNeedAnalyticsPush && this.firstLevelAnalytics()
      },
      dropdownToggleByKeyboard() {
        this.isOpened = !this.isOpened

        // v-show не успевает смениться без $nextTick
        this.$nextTick(() => {
          this.isOpened
            ? activateFocusTrap(this.$el)
            : deactivateFocusTrap(this.$el, -1)

          const isNeedAnalyticsPush = this.item.dropdown && this.isOpened
          isNeedAnalyticsPush && this.firstLevelAnalytics()
        })
      },
      checkActive() {
        return false
      },
      innerLinkClickHandler(dropdownItemName) {
        this.isOpened = false
        this.$emit('close')
        deactivateFocusTrap(this.$el)

        this.$analytics && this.$analytics.push('header-second-level-click', {
          buttonName: this.item.analytics.title,
          dropdownItemName
        })
      },
      firstLevelAnalytics() {
        this.$analytics && this.$analytics.push('header-first-level-click', {
          buttonName: this.item.analytics.title
        })
      }
    }
  }
</script>

<style lang="scss">
  .ui-header#ui-header {
    .ui-header-nav-item {
      position: relative;
      outline: none;

      &--opened {
        &::before {
          position: absolute;
          bottom: -8px;
          left: 0;
          display: block;
          width: 100%;
          height: 8px;
          content: '';
        }
      }
    }

    .ui-header-nav-item__dropdown {
      position: absolute;
      top: calc(100% + 8px);
    }

    .slide-fade-enter-active {
      transition: all .15s ease-in-out;
    }

    .slide-fade-leave-active {
      transition: all .15s ease-in-out;
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      transform: translateY(-3px);
      opacity: 0;
    }
  }
</style>
