export default {
  'header-first-level-click': (data, { provider, projectForAnalytics }) => {
    const { buttonName } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: `${buttonName} button clicked`
    })
  },

  'header-second-level-click': (data, { provider, projectForAnalytics }) => {
    const { buttonName, dropdownItemName } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: `Item ${buttonName} skillbox selected`,
      label: dropdownItemName
    })
  },

  'header-all-courses-click': (data, { provider, projectForAnalytics }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: 'All courses button clicked'
    })
  },

  'header-toggle-click': (data, { provider, projectForAnalytics }) => {
    const { status } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: 'Menu button clicked',
      label: status
    })
  },

  'header-sale-click': (data, { projectForAnalytics, provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: 'Sell-out button clicked'
    })
  },

  'header-search-submit': (data, { projectForAnalytics, provider }) => {
    const { search } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: 'Search',
      label: search
    })
  },

  'header-mba-click': (data, { projectForAnalytics, provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: 'MBA training button clicked'
    })
  },

  'header-higher-education-click': (data, { projectForAnalytics, provider }) => {
    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: 'Higher education button clicked'
    })
  },

  'header-direction-clicked': (data, { provider, projectForAnalytics }) => {
    const { title } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: 'Direction clicked',
      label: title
    })
  },

  'header-popular-click': (data, { provider, projectForAnalytics }) => {
    const { title, type } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: `Click on ${type}`,
      label: title
    })
  },

  'header-popular-change-slide': (data, { provider, projectForAnalytics }) => {
    const { blockName, activeIndex } = data

    provider.$gtm.push({
      event: 'mainEvent',
      category: `Header - ${projectForAnalytics}`,
      action: 'Carousel scrolling',
      label: activeIndex,
      blockName,
      context: {
        blockName
      }
    })
  },

  // Legacy события
  'header-legacy': (data, { provider }) => {
    provider.$gtm.push(data)
  }
}
