<template>
  <svg
    v-if="name === 'ui-close'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.2929 5.2929c.3905-.3905 1.0237-.3905 1.4142 0L12 10.5858l5.2929-5.293c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L13.4142 12l5.2929 5.2929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L12 13.4142l-5.2929 5.2929c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L10.5858 12l-5.293-5.2929c-.3904-.3905-.3904-1.0237 0-1.4142Z" fill="currentColor"/>
  </svg>

  <svg
    v-else-if="name === 'ui-burger'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM2 12a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1ZM2 19a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Z" fill="currentColor"/>
  </svg>

  <svg
    v-else-if="name === 'ui-chevron-down'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="m12 15.5858-7.2929-7.293c-.3905-.3904-1.0237-.3904-1.4142 0-.3905.3906-.3905 1.0238 0 1.4143l7.8586 7.8586c.4686.4686 1.2284.4686 1.697 0l7.8586-7.8586c.3905-.3905.3905-1.0237 0-1.4142-.3905-.3905-1.0237-.3905-1.4142 0L12 15.5858Z" fill="currentColor"/>
  </svg>

  <svg
    v-else-if="name === 'ui-chevron-left'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="m8.4144 12 7.2929-7.2929c.3905-.3905.3905-1.0237 0-1.4142-.3906-.3905-1.0237-.3905-1.4142 0l-7.8586 7.8586c-.4687.4686-.4687 1.2284 0 1.697l7.8586 7.8586c.3905.3905 1.0236.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L8.4143 12Z" fill="currentColor"/>
  </svg>

  <svg
    v-else-if="name === 'ui-chevron-right'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="m15.5858 12-7.293-7.2929c-.3904-.3905-.3904-1.0237 0-1.4142.3906-.3905 1.0238-.3905 1.4143 0l7.8586 7.8586c.4686.4686.4686 1.2284 0 1.697l-7.8586 7.8586c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L15.5858 12Z" fill="currentColor"/>
  </svg>

  <svg
    v-else-if="name === 'ui-search'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4c-3.3137 0-6 2.6863-6 6s2.6863 6 6 6 6-2.6863 6-6-2.6863-6-6-6Zm-8 6c0-4.4183 3.5817-8 8-8s8 3.5817 8 8c0 4.4182-3.5817 8-8 8s-8-3.5818-8-8Z" fill="currentColor"/><path d="M14.7071 16.1211c-.3905-.3905-.3905-1.0237 0-1.4142.3905-.3905 1.0237-.3905 1.4142 0l4.9498 4.9498c.3905.3905.3905 1.0237 0 1.4142-.3906.3905-1.0237.3905-1.4142 0l-4.9498-4.9498Z" fill="currentColor"/>
  </svg>

  <svg
    v-else-if="name === 'ui-arrow-left'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7073 5.7071c.3905-.3905.3905-1.0237 0-1.4142-.3906-.3905-1.0237-.3905-1.4142 0l-6.8586 6.8586c-.4686.4686-.4686 1.2284 0 1.697l6.8586 6.8586c.3905.3905 1.0236.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L5.4143 13H21c.5523 0 1-.4477 1-1s-.4477-1-1-1H5.4144l5.2929-5.2929Z" fill="currentColor"/>
  </svg>

  <svg
    v-else-if="name === 'ui-close-circle-dark'"
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12c0 5.5228-4.4772 10-10 10-5.5229 0-10-4.4772-10-10C2 6.4771 6.4771 2 12 2c5.5228 0 10 4.4771 10 10ZM8.2929 8.2929c.3905-.3905 1.0237-.3905 1.4142 0L12 10.5858l2.2929-2.293c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L13.4142 12l2.2929 2.2929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L12 13.4142l-2.2929 2.2929c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L10.5858 12l-2.293-2.2929c-.3904-.3905-.3904-1.0237 0-1.4142Z" fill="currentColor"/>
  </svg>

  <span v-else></span>
</template>

<script>
  export default {
    name: 'SvgIcon',
    props: {
      name: {
        type: String,
        required: true
      },
      width: {
        type: String,
        default: null
      },
      height: {
        type: String,
        default: null
      }
    }
  }
</script>
