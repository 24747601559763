<template>
  <ul class="ui-header-directions">
    <li
      v-for="(item, i) in directions"
      :key="i"
      class="ui-header-directions__item"
    >
      <UiListItemLink
        :title="item.title"
        v-bind="getLinkParams(item)"
        :is-active="isRouteDirections && $route.path === item.link"
        theme="main"
        icon-position="left"
        size="small"
        class="ui-header-directions__link"
        @click.native="directionClickHandler(item)"
      >
        <template #image>
          <img
            :src="isDarkMode ? item.icon_dark : item.icon_light"
            alt=""
          >
        </template>
      </UiListItemLink>
    </li>
  </ul>
</template>

<script>
  import { mapGetters } from 'vuex'
  import UiListItemLink from '@skillbox/front-box/dist/Items/UiListItemLink/UiListItemLink'

  export default {
    name: 'UiHeaderDirections',
    components: {
      UiListItemLink
    },
    props: {
      isRouteDirections: {
        type: Boolean,
        default: false
      },
      isDarkMode: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
        directions: 'headerDirections/getHeaderDirections'
      })
    },
    methods: {
      getLinkParams(item) {
        if (this.isRouteDirections) {
          return {
            to: item.link
          }
        } else {
          return {
            href: item.href,
            target: '_blank'
          }
        }
      },
      directionClickHandler({ title }) {
        this.$analytics && this.$analytics.push('header-direction-clicked', { title })
        this.$analytics && this.$analytics.push('header-legacy', { event: 'main_popup_click', parametr1: title })
        this.$emit('click-direction')
      }
    }
  }
</script>

<style lang="scss">
  .ui-header#ui-header {
    .ui-header-directions {
      @include ulres;

      margin-bottom: -4px;

      @include media(xl) {
        display: flex;
        flex-wrap: wrap;
        padding-top: 14px;
        column-gap: 20px;
      }
    }

    .ui-header-directions__item {
      margin-bottom: 4px;

      @include media(xl) {
        width: 227px;
      }
    }
  }
</style>
