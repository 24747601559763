import Vue from 'vue'
import { ClientIds } from '@skillbox/client-ids'

export default () => {
  const clientIds = new ClientIds({ log: console.error })

  Vue.prototype.$clientIds = {
    getSkbId: () => clientIds.getSkbId(),
    getGaId: async () => { await clientIds.getGaId() }
  }
}
