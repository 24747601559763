<template>
  <div id="app">
    <Header data-theme="light" />

    <portal-target
      id="portal-modals"
      data-theme="light"
      name="modals"
    />
  </div>
</template>

<script>
  import Header from '@/components/UiHeaderNew/UiHeader'
  import { mapActions } from 'vuex'

  export default {
    name: 'App',
    components: { Header },
    beforeMount() {
      this.fetchCards()
      this.fetchHeaderDirections()
    },
    methods: {
      ...mapActions({
        fetchCards: 'headerCourses/fetchCards',
        fetchHeaderDirections: 'headerDirections/fetchHeaderDirections'
      })
    }
  }
</script>

<style lang="scss">
   //перебиваем стили тильды
   #portal-modals .ui-popup.ui-search-mobile {
     z-index: 57; // приходится менять из-за тильды
   }

  .t-records,
  #allrecords {
    .ui-popup,
    .ui-header {
      a {
        color: inherit;

        &:focus-visible {
          outline-color: unset;
          outline-offset: unset;
          outline-style: unset;
        }
      }

      button:focus-visible {
        outline-color: unset;
        outline-offset: unset;
        outline-style: unset;
      }

      // усиляем селектор из фб, так как тильда перебивает
      .ui-tab--grey.ui-tab--interacted-from-outside,
      .ui-tab--grey:focus-visible,
      .ui-tab--stroke-white.ui-tab--interacted-from-outside,
      .ui-tab--stroke-white:focus-visible,
      .ui-tab--stroke.ui-tab--interacted-from-outside,
      .ui-tab--stroke:focus-visible {
        border-color: var(--accent-brand-color);
        color: var(--accent-always-white-color);
        background-color: var(--accent-brand-color);
      }

      @include media(lg) {
        .ui-tab--grey:hover,
        .ui-tab--stroke-white:hover,
        .ui-tab--stroke:hover {
          background-color: var(--accent-brand-color);
          border-color: var(--accent-brand-color);
          color: var(--accent-always-white-color);
        }
      }
    }
  }

  // перебиваем фокус для старой сафари
  .is-safari {
    .t-records,
    #allrecords {
      .ui-tab {
        &--grey:focus,
        &--stroke:focus,
        &--stroke-white:focus,
        &--grey.ui-tab--interacted-from-outside,
        &--stroke.ui-tab--interacted-from-outside,
        &--stroke-white.ui-tab--interacted-from-outside {
          border-color: var(--accent-brand-color);
          color: var(--accent-always-white-color);
          background-color: var(--accent-brand-color);
        }
      }
    }
  }
</style>
