export default (makeRequest, $config) => ({
  /**
   * @param queryParams { object } объект с query для выборки лекций
   * @param queryParams.page { number } запрашиваемая страница.
   * @param queryParams.limit { string } количество карточек.
   * @param queryParams.with_employment { string } трудоустройство (1/0 по просьбе бэка).
   * @param queryParams.level { string } Уровень сложности.
   * @param queryParams.duration_min { number } время обучения от.
   * @param queryParams.duration_max { number } время обучения до.
   * @param queryParams.topics { array of string } массив тематик.
   * @param queryParams.direction { string } направление (хз зачем, итак есть в параметрах).
   * @param queryParams.type { string } курс/профессия (хз зачем, итак есть в параметрах).
   * @param queryParams.search { string } поиск .
   * @param queryParams.faculty { string } факультет.
   * @param queryParams.sc { string } параметр для GA, направление по которому что-то анализируют.
   */
  get(queryParams) {
    return makeRequest({
      url: `${$config.skillboxApi}api/v6/ru/sales/skillbox/directions/all/nomenclature/profession/?page=1&limit=24`,
      method: 'GET',
      params: queryParams
    })
  }
})
