<template>
  <nuxt-link
    v-if="logoIsRoute"
    to="/"
    class="ui-header-logo"
  >
    <img
      :src="logo"
      alt="Skillbox"
      width="84"
      height="19"
      class="ui-header-logo__image"
    >
  </nuxt-link>

  <a
    v-else
    href="https://skillbox.ru"
    target="_blank"
    rel="noopener"
    class="ui-header-logo"
  >
    <img
      :src="logo"
      alt="Skillbox"
      width="84"
      height="19"
      class="ui-header-logo__image"
    >
  </a>
</template>

<script>
  export default {
    name: 'UiHeaderLogo',
    props: {
      isDarkMode: {
        type: Boolean,
        default: false
      },
      logoIsRoute: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      logo() {
        return `https://cdn.skillbox.pro/wbd-front/skillbox-static/skillbox${this.isDarkMode ? '-dark' : ''}.svg`
      }
    }
  }
</script>

<style lang="scss">
  .is-safari .ui-header#ui-header .ui-header-logo:focus::before {
    background-color: var(--bg-secondary-color);
  }

  .ui-header#ui-header {
    /* stylelint-disable */
    .ui-header-logo {
      position: relative;
      width: 84px;
      transition: transform 300ms ease;

      @include media(md) {
        width: 108px;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        border-radius: 50px;
        background-color: transparent;
        transform: translate(-50%, -50%);
        transition: background-color 300ms ease;
        content: '';
      }

      &:focus-visible::before {
        background-color: var(--bg-secondary-color);
      }
    }
    /* stylelint-enable */

    .ui-header-logo__image {
      position: relative;
      display: block;
      width: 100%;
    }
  }
</style>
