import Vue from 'vue'
import axios from 'axios'

import makeRequestFactory from '@/plugins/api/makeRequestFactory'
import HeaderDirections from '@/components/UiHeaderNew/components/Menu/modules/Directions/api/header-directions'
import HeaderCourses from '@/components/UiHeaderNew/components/Menu/modules/Courses/api/header-courses'
import Suggestions from '@/plugins/api/repositories/suggestions'

export default () => {
  const makeRequest = makeRequestFactory(axios, { log: console.error })

  Vue.prototype.$api = {
    headerDirections: HeaderDirections(makeRequest, { skillboxApi: 'https://skillbox.ru/' }),
    headerCourses: HeaderCourses(makeRequest, { skillboxApi: 'https://skillbox.ru/' }),
    suggestions: Suggestions(makeRequest, { skillsearchApi: 'https://skillbox.ru/' })
  }
}
