<template>
  <transition name="ui-header-mobile-menu">
    <div
      ref="headerContent"
      v-bsl="isVisible"
      v-escape="close"
      :style="{
        height: `${height}px`
      }"
      class="ui-header-mobile-menu"
      @scroll="scrollHandler"
    >
      <div
        class="ui-header-mobile-menu__sticky-block"
        :class="{'ui-header-mobile-menu__sticky-block--scrolled': isMobileMenuScrolled}"
      >
        <div class="ui-container">
          <div class="ui-header-mobile-menu__nav">
            <UiTab
              :key="navigation.allCoursesMobile.slug"
              size="medium"
              theme="grey"
              :is-bold="true"
              :is-active="activeView === navigation.allCoursesMobile.slug"
              class="ui-header-mobile-menu__tab"
              @click="clickTabHandler(navigation.allCoursesMobile)"
            >
              {{ navigation.allCoursesMobile.title }}
            </UiTab>

            <template v-for="(item, i) in navigation.links">
              <UiTab
                v-if="item.dropdown"
                :key="i"
                theme="grey"
                :is-bold="true"
                size="medium"
                :is-active="activeView === item.slug"
                class="ui-header-mobile-menu__tab"
                @click="clickTabHandler(item)"
              >
                {{ item.title }}
              </UiTab>

              <UiTab
                v-else
                :key="i"
                :picture="item.picture"
                theme="grey"
                :is-bold="true"
                icon="ui-chevron-right"
                media-position="right"
                size="medium"
                :href="item.href"
                target="_blank"
                class="ui-header-mobile-menu__tab"
                @click="clickTabHandler(item, true)"
              >
                {{ item.title }}
              </UiTab>
            </template>
          </div>
        </div>
      </div>

      <div
        v-show="activeView === navigation.allCoursesMobile.slug"
        class="ui-header-mobile-menu__content"
      >
        <div class="ui-container">
          <UiSearchButton
            size="medium"
            placeholder="Профессия или навык"
            class="ui-header-mobile-menu__search"
            @click="isShowSearch = true"
          />

          <UiSearchMobile
            v-if="isShowSearch"
            :disabled-portal="true"
            :get-suggestions="$api.suggestions.get"
            :user-id="userId"
            placeholder="Какой курс вы ищете?"
            @update="menuSearchHandler"
            @close-search="searchCloseHandler"
          />

          <UiHeaderDirections
            :is-route-directions="isRouteDirections"
            :is-dark-mode="isDarkMode"
            class="ui-header-mobile-menu__directions"
            @click-direction="close"
          />
        </div>

        <UiHeaderCourses
          class="ui-header-mobile-menu__courses"
          @update-focusable-slides="$nextTick(updateFocusTrap)"
        />
      </div>

      <div class="ui-header-mobile-menu__content ui-container">
        <UiHeaderDropdownMobile
          v-for="(item, i) in sectionsWithDropdowns"
          v-show="activeView === item.slug"
          :key="`dropdown-${i}`"
          :is-routes-inside="item.isRoute"
          :dropdown="item.dropdown"
          class="ui-header-mobile-menu__dropdown"
          @link-click="close"
        />
      </div>
    </div>
  </transition>
</template>

<script>
  import { activateFocusTrap, deactivateFocusTrap } from '@skillbox/front-box/dist/utils/focus-tab'

  import UiTab from '@skillbox/front-box/dist/InteractiveElements/UiTab/UiTab'
  import UiSearchMobile from '@skillbox/front-box/dist/Blocks/UiSearch/components/UiSearchMobile/UiSearchMobile'
  import UiSearchButton from '@skillbox/front-box/dist/Blocks/UiSearch/components/UiSearchButton/UiSearchButton'
  import UiHeaderDirections from './modules/Directions/UiHeaderDirections'
  import UiHeaderCourses from './modules/Courses/UiHeaderCourses'
  import UiHeaderDropdownMobile from './../nav/mobile/UiHeaderDropdownMobile'

  export default {
    name: 'UiHeaderMobileMenu',
    components: {
      UiSearchButton,
      UiSearchMobile,
      UiTab,
      UiHeaderDirections,
      UiHeaderCourses,
      UiHeaderDropdownMobile
    },
    props: {
      navigation: {
        type: Object,
        required: true
      },
      isRouteDirections: {
        type: Boolean,
        default: false
      },
      isVisible: {
        type: Boolean,
        default: false
      },
      userId: {
        type: String,
        default: undefined
      },
      isDarkMode: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        activeView: 'all-courses',
        isShowSearch: false,
        isMobileMenuScrolled: false,
        height: 0
      }
    },
    computed: {
      sectionsWithDropdowns() {
        return this.navigation.links.filter(item => item.dropdown)
      }
    },
    watch: {
      isVisible(val) {
        // так как тут v-show, то нельзя завязаться на хуки
        if (val) {
          this.$nextTick(() => {
            this.setActiveSection()
            this.setMenuHeight()
            window.addEventListener('resize', this.setMenuHeight)

            this.activateFocusTrap()
          })
        } else {
          this.$refs.headerContent && deactivateFocusTrap(this.$refs.headerContent)
          window.removeEventListener('resize', this.setMenuHeight)
          this.$el && this.$el.scrollTo(0, 0)
        }
      },
      activeView(val) {
        this.$nextTick(() => {
          this.updateFocusTrap()
        })
      }
    },
    mounted() {
      screen?.orientation?.addEventListener('change', this.setMenuHeight)
    },
    beforeDestroy() {
      screen?.orientation?.removeEventListener('change', this.setMenuHeight)
    },
    methods: {
      searchCloseHandler() {
        this.isShowSearch = false
        this.$nextTick(() => {
          this.$refs.headerContent && deactivateFocusTrap(this.$refs.headerContent)
          this.activateFocusTrap()
        })
      },
      activateFocusTrap() {
        this.$refs.headerContent && activateFocusTrap(this.$refs.headerContent)
        this.$refs.headerContent && this.$refs.headerContent.focus({ preventScroll: true })
      },
      updateFocusTrap() {
        this.$refs.headerContent && activateFocusTrap(this.$refs.headerContent)
      },
      scrollHandler(e) {
        this.isMobileMenuScrolled = e.target && e.target.scrollTop > 0
      },
      setActiveSection() {
        let activeView = this.navigation.allCoursesMobile.slug
        const activeSection = this.navigation.links.find(item => item.isRoute)

        if (activeSection) {
          if (!activeSection.excludeUnDescribedRoutes) {
            // кейс, когда раздел активен независимо от активности подпунктов
            activeView = activeSection.slug
          } else {
            // кейс, когда активность раздела зависит от активности пунктов внутри
            const activeDropdownItem = activeSection.dropdown.find(dropdownItem => dropdownItem.to === this.$route.path)

            if (activeDropdownItem) {
              activeView = activeSection.slug
            }
          }
        }

        this.activeView = activeView
      },
      menuSearchHandler(value) {
        this.$analytics && this.$analytics.push('header-search-submit', { search: value })
        window.location.href = `https://skillbox.ru/courses/?search=${encodeURIComponent(value)}&sc=courses`
      },
      close() {
        this.$emit('close')
      },
      setMenuHeight() {
        this.$nextTick(() => {
          // тут через не boundingClientRect от кнопки, потому что сафари считает неправильно
          const banner = document.querySelector('.universal-notice')
          const header = document.querySelector('.ui-header')

          const bannerHeight = (banner && banner.offsetHeight) || 0
          const headerHeight = (header && header.offsetHeight) || 0
          const offsetTop = headerHeight + bannerHeight
          const windowHeight = window.innerHeight
          this.height = windowHeight - offsetTop
        })
      },
      clickTabHandler(item, isOuterLink = false) {
        if (!isOuterLink) {
          this.activeView = item.slug

          this.$nextTick(() => {
            this.$el && this.$el.scrollTo(0, 0)
          })
        }

        this.$analytics && this.$analytics.push('header-first-level-click', {
          buttonName: item.analytics.title
        })

        if (item.analytics && item.analytics.legacyPush && this.$analytics) {
          this.$analytics.push('header-legacy', { event: item.analytics.legacyPush })
        }

        isOuterLink && this.close()
      }
    }
  }
</script>

<style lang="scss">
  .ui-header#ui-header {
    .ui-header-mobile-menu {
      position: fixed;
      z-index: 52;

      width: 100%;
      left: 0;
      overflow-y: auto;
      background-color: var(--bg-main-color);
      animation: .4s ease-in-out forwards ui-header-mobile-menu-show;
      -webkit-overflow-scrolling: touch;

      padding-bottom: 40px;

      &::-webkit-scrollbar {
        display: none;
      }

      &:focus {
        outline: none;
      }
    }

    .ui-header-mobile-menu.ui-header-mobile-menu-leave-to {
      animation: .4s ease-in-out forwards ui-header-mobile-menu-hide;
    }

    .ui-header-mobile-menu__sticky-block {
      @include z-index(sticky, header, overlay);

      top: 0;
      margin-bottom: 12px;
      padding-top: 8px;
      padding-bottom: 20px;
      background-color: var(--bg-main-color);

      &--scrolled {
        @include ui-shadow-4pt;
      }
    }

    .ui-header-mobile-menu__nav {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: min-content;
      grid-gap: 8px;
      margin: 0 -20px;
      padding: 0 20px;
      overflow-x: auto;
      scrollbar-width: none; // firefox

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
      }
    }

    .ui-header-mobile-menu__directions {
      margin-left: -14px;
      margin-right: -14px;
    }

    .ui-header-mobile-menu__courses {
      margin-top: 52px;
    }

    .ui-header-mobile-menu__search {
      margin-bottom: 24px;
    }

    .ui-header-mobile-menu__dropdown {
      margin-left: -14px;
      margin-right: -14px;
    }

    @keyframes ui-header-mobile-menu-show {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes ui-header-mobile-menu-hide {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
</style>
