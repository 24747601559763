import Vue from 'vue'
import headerEvents from '@/components/UiHeaderNew/analytics/headerEvents'

const allEvents = headerEvents

export default ({ store }) => {
  const $Analytic = class {
    constructor(customOptions, initCallback) {
      this.listeners = {}

      this.options = {
        isLogEnable: false,
        platform: '',
        projectForAnalytics: '',
        pageName: '',
        fullPath: '',
        ...customOptions
      }

      if (initCallback && typeof initCallback === 'function') {
        initCallback()
      }
    }

    push(eventName, data = null) {
      const handlers = this.listeners[eventName]

      this.log(eventName, data)

      if (handlers && handlers.length) {
        handlers.forEach((fnc) => {
          try {
            fnc(data, this.options)
          } catch (e) {
            console.error(e)
          }
        })
        return
      }

      this.log(eventName, 'Listener not found')
    }

    log(event, data) {
      if (this.options.isLogEnable) {
        console.log(event, data)
      }
    }

    createEvent(eventName, listener) {
      const isListOfListeners = Array.isArray(listener)

      if (!this.listeners[eventName]) {
        this.listeners[eventName] = []
      }

      if (isListOfListeners) {
        this.listeners[eventName].push(...listener)
      } else {
        this.listeners[eventName].push(listener)
      }
    }

    setOptions(options) {
      this.options = { ...this.options, ...options }
    }
  }

  window.dataLayer = window.dataLayer || []

  const $analytics = new $Analytic({
    store,
    platform: 'skillbox',
    projectForAnalytics: 'Tilda',
    fullPath: '/',
    path: '/',
    pageName: '',
    provider: {
      $gtm: window.dataLayer
    }
  })

  window.$analytics = $analytics

  Object.keys(allEvents).forEach(event => $analytics.createEvent(event, allEvents[event]))

  Vue.prototype.$analytics = window.$analytics
}
