<template>
  <UiTab
    :href="login.href"
    target="_blank"
    theme="additional"
    size="small"
    size-mode="medium-xl"
    :is-bold="false"
    @click="$analytics && $analytics.push('header-first-level-click', { buttonName: login.analytics && login.analytics.title })"
  >
    {{ login.title }}
  </UiTab>
</template>

<script>
  import UiTab from '@skillbox/front-box/dist/InteractiveElements/UiTab/UiTab'

  export default {
    name: 'UiHeaderLogin',
    components: {
      UiTab
    },
    props: {
      login: {
        type: Object,
        required: true
      }
    }
  }
</script>
