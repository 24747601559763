import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueMq from 'vue-mq'
import VBodyScrollLock from 'v-body-scroll-lock'
import PortalVue from 'portal-vue'

import customAnalytics from './plugins/customAnalytics.js'
import api from './plugins/api/plugin.js'
import isSafari from './plugins/isSafari.js'
import clientIds from './plugins/client-ids.js'

import '@skillbox/front-box/dist/Functional/UiFonts/style.scss'
import '@skillbox/front-box/dist/Functional/UiColors/style.scss'
import '@skillbox/front-box/dist/Functional/UiStyle/style.scss'
import '@/assets/scss/scaffolding.scss'

import UiTab from '@skillbox/front-box/dist/InteractiveElements/UiTab/UiTab'
import SvgIcon from '@/components/SvgIcon'
import NuxtLink from '@/components/NuxtLink'

import { Swiper as SwiperClass, Pagination, Navigation, Autoplay, EffectFade, FreeMode } from 'swiper'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

SwiperClass.use([Pagination, Autoplay, Navigation, EffectFade, FreeMode])
Vue.use(getAwesomeSwiper(SwiperClass))

Vue.component('svg-icon', SvgIcon)
Vue.component('nuxt-link', NuxtLink)
Vue.component('UiTab', UiTab)

Vue.use(customAnalytics)
Vue.use(api)
Vue.use(isSafari)
Vue.use(clientIds)
Vue.use(PortalVue)

store.$api = Vue.prototype.$api // костыль так как плагин не инжектится в стор как в наксте

Vue.mixin({
  computed: {
    isTabletMax() {
      return this.$mq === 'smMax' || this.$mq === 'mdMax'
    },
    isLgMax() {
      return this.isTabletMax || this.$mq === 'lgMax'
    },
    isLg() {
      return this.$mq === 'lgMax' || this.$mq === 'xl'
    }
  }
})

Vue.use(VBodyScrollLock, {
  bodyScrollOptions: {
    allowTouchMove: el => el.closest(['body-scroll-lock-ignore'])
  }
})

const breakpoints = {
  smMax: 768,
  mdMax: 1024,
  lgMax: 1280,
  xl: Infinity
}

Vue.use(VueMq, {
  defaultBreakpoint: 'default',
  breakpoints
})

Vue.config.productionTip = false

Vue.directive('escape', {
  bind(el, binding, vnode) {
    if (window.screen.width >= breakpoints.mdMax) {
      el.escKeydownHandler = (event) => {
        if (event.key === 'Escape') {
          if (typeof binding.value !== 'function') {
            vnode.context[binding.arg] = binding.value
          } else {
            vnode.context[binding.expression](event, binding.arg)
          }
        }
      }

      document.body.addEventListener('keydown', el.escKeydownHandler)
    }
  },
  unbind(el) {
    document.body.removeEventListener('keydown', el.escKeydownHandler)
    el.escKeydownHandler = null
  }
})

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      const isCurrentElement = el === event.target || el.contains(event.target)

      if (!isCurrentElement) {
        const ctx = vnode.context

        if (!ctx[binding.arg]) {
          return
        }

        if (typeof binding.value !== 'function') {
          ctx[binding.arg] = binding.value
        } else {
          ctx[binding.expression](event, binding.arg)
        }
      }
    }

    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
    el.clickOutsideEvent = null
  }
})

Vue.prototype.$logger = {
  log(e) {
    console.error(e)
  }
}

new Vue({
  store,
  render: h => h(App)
}).$mount('#header')
