<template>
  <nav class="ui-header-nav-bar">
    <ul class="ui-header-nav-bar__list">
      <li
        v-for="(item, i) in navigation.links"
        :key="i"
        class="ui-header-nav-bar__item"
      >
        <UiHeaderNavItem
          :item="item"
          @close="$emit('close-menu')"
        />
      </li>

      <li
        v-if="!isHideLogin"
        class="ui-header-nav-bar__item ui-header-nav-bar__item--auth"
      >
        <UiHeaderLogin :login="navigation.login" />
      </li>
    </ul>
  </nav>
</template>

<script>
  import UiHeaderLogin from '../../UiHeaderLogin'
  import UiHeaderNavItem from './UiHeaderNavItem'

  export default {
    name: 'UiHeaderNavBar',
    components: {
      UiHeaderNavItem,
      UiHeaderLogin
    },
    props: {
      navigation: {
        type: Object,
        required: true
      },
      isHideLogin: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss">
  .ui-header#ui-header {
    .ui-header-nav-bar__list {
      @include ulres;

      display: flex;
    }

    .ui-header-nav-bar__item {
      line-height: 0;

      &:not(:last-child) {
        margin-right: 13px;
      }

      &--auth {
        margin-left: 28px;
      }
    }
  }
</style>
