<template>
  <!--  заглушка, тут не может быть nuxt-link, а без нее падает-->
  <RouterLink :to="to">
    <slot />
  </RouterLink>
</template>

<script>
  export default {
    name: 'NuxtLink',
    props: {
      to: {
        type: [String, Object],
        required: true
      }
    }
  }
</script>
