const adaptImages = (images) => {
  return images
    ? {
      sm: {
        img: {
          '1x': images.thumbnail.image
        },
        webp: {
          '1x': images.thumbnail.webp
        }
      }
    }
    : null
}

export const state = () => ({
  headerCourses: []
})

export const mutations = {
  setHeaderCourses(state, headerCourses) {
    state.headerCourses = headerCourses
  }
}

export const actions = {
  async fetchCards({ commit, state }) {
    if (state.headerCourses.length) {
      return
    }

    await this.$api.headerCourses.get()
      .then(({ data: res }) => {
        const headerCourses = res.data

        headerCourses.forEach((course) => {
          course.images = adaptImages(course.images)
          course.href = `https://skillbox.ru${course.href}`
        })

        commit('setHeaderCourses', headerCourses)
      })
      .catch((e) => {
        this.$logger.log(e)
      })
  }
}

export const getters = {
  getHeaderCourses({ headerCourses }) {
    return headerCourses
  }
}
