<template>
  <div class="ui-header-courses">
    <div class="ui-header-courses__container">
      <div
        ref="slider"
        v-swiper:mainSlider="sliderSettings"
        class="ui-header-courses__wrapper"
      >
        <h2 class="ui-header-courses__title t t--2">
          Популярное
        </h2>

        <div class="ui-header-courses__slider-controls">
          <UiIconButton
            theme="filled-secondary"
            icon="ui-chevron-left"
            size="extra-small"
            aria-label="Предыдущий слайд"
            class="ui-header-courses__slider-control ui-header-courses__slider-control--prev"
          />

          <UiIconButton
            theme="filled-secondary"
            icon="ui-chevron-right"
            size="extra-small"
            aria-label="Следующий слайд"
            class="ui-header-courses__slider-control ui-header-courses__slider-control--next"
          />
        </div>

        <ul class="ui-header-courses__list">
          <li
            v-for="(item, i) in groupedCourses"
            :key="i"
            class="ui-header-courses__slide"
          >
            <UiProductCardSmall
              v-for="(groupItem, j) in item"
              :key="j"
              :is-focusable="focusableSlideIndexes.includes(i)"
              :card="groupItem"
              :click-callback="analyticsPush"
              :is-title="false"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import UiIconButton from '@skillbox/front-box/dist/Buttons/UiIconButton/UiIconButton'
  import UiProductCardSmall from '@skillbox/front-box/dist/Blocks/UiProductCardSmall/UiProductCardSmall'

  export default {
    name: 'UiHeaderCourses',
    components: {
      UiIconButton,
      UiProductCardSmall
    },
    data() {
      return {
        activeSlideIndex: 0,
        focusableSlideIndexes: [this.activeSlideIndex]
      }
    },
    computed: {
      ...mapGetters({
        headerCourses: 'headerCourses/getHeaderCourses',
        directions: 'headerDirections/getHeaderDirections'
      }),
      sliderSettings() {
        return {
          threshold: 40,
          slideCount: this.groupedCourses.length,
          slideClass: 'ui-header-courses__slide',
          allowAutoplay: true,
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          speed: 400,
          spaceBetween: 20,
          centeredSlides: false,
          navigation: {
            prevEl: '.ui-header-courses__slider-control--prev',
            nextEl: '.ui-header-courses__slider-control--next'
          },
          wrapperClass: 'ui-header-courses__list',
          breakpoints: {
            768: {
              slidesPerGroup: 2
            },
            1024: {
              slidesPerGroup: 3
            },
            1280: {
              slidesPerGroup: 2
            }
          },
          on: {
            slideChange: (swiper) => {
              /* eslint-disable */
              this.activeSlideIndex = swiper.realIndex
              this.setFocusableSlides(this.activeSlideIndex)

              this.$analytics && this.$analytics.push('header-popular-change-slide', {
                activeIndex: Math.ceil(swiper.realIndex / swiper.params.slidesPerGroup) + 1, // начинается с 0
                blockName: 'header-popular-slider'
              })
              /* eslint-enable */
            }
          }
        }
      },
      groupedCourses() {
        const sliderData = []
        let DELTA = 4

        if (this.directions.length > 16 && this.$mq === 'xl') {
          DELTA = 6
        }

        const iterationCount = Math.ceil(this.headerCourses.length / DELTA) - 1

        for (let i = 0; i <= iterationCount; i++) {
          const startIndex = i * DELTA
          const endIndex = startIndex + DELTA
          sliderData.push(this.headerCourses.slice(startIndex, endIndex))
        }

        return sliderData
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.activeSlideIndex = this.mainSlider.realIndex
        this.setFocusableSlides(this.activeSlideIndex)
      })
    },
    methods: {
      analyticsPush({ title, type }) {
        this.$analytics && this.$analytics.push('header-popular-click', { title, type })
      },
      setFocusableSlides(index) {
        // делаем доступными для фокуса только карточки на видимых слайдах
        switch (this.$mq) {
          case 'smMax':
            this.focusableSlideIndexes = [this.activeSlideIndex]
            break
          case 'mdMax':
          case 'lgMax':
            this.focusableSlideIndexes = [this.activeSlideIndex, this.activeSlideIndex + 1, this.activeSlideIndex + 2]
            break
          case 'xl':
            this.focusableSlideIndexes = [this.activeSlideIndex, this.activeSlideIndex + 1]
            break
        }

        this.$emit('update-focusable-slides')
      }
    }
  }
</script>

<style lang="scss">
  .ui-header#ui-header {
    .ui-header-courses {
      overflow: hidden;

      @include media(xl) {
        position: relative;
        margin-left: -40px;
        margin-right: -26px;
        padding-left: 40px;
        padding-right: 26px;

        &::before {
          @include z-index(absolute, base);

          left: 0;
          top: 0;
          width: 40px;
          height: 100%;
          display: block;
          content: '';
        }

        &::after {
          @include z-index(absolute, base);

          right: 0;
          top: 0;
          width: 40px;
          height: 100%;
          display: block;
          content: '';
        }
      }
    }
  }

  [data-theme='light'] {
    .ui-header-courses::before,
    .ui-header-courses::after {
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 48%);
    }

    .ui-header-courses::after {
      transform: rotate(180deg);
    }
  }

  [data-theme='dark'] {
    .ui-header-courses::before,
    .ui-header-courses::after {
      background: linear-gradient(270deg, rgba(20, 20, 20, 0.00) 0%, #141414 48%);
    }

    .ui-header-courses::after {
      transform: rotate(180deg);
    }
  }

  .ui-header#ui-header {
    .ui-header-courses__container {
      @include media(lg-max) {
        @include ui-container(sm);
      }
    }

    .ui-header-courses__wrapper {
      display: grid;
      grid-gap: 24px;
      grid-template-columns: 1fr auto;
      align-items: center;

      @include media(xl) {
        grid-gap: 28px;
      }
    }

    .ui-header-courses__wrapper:not(.swiper-initialized) .ui-header-courses__slide {
      margin-right: 20px;
    }

    .ui-header-courses__slide {
      display: grid;
      grid-gap: 20px;
      width: calc(100% - 14px);
      flex-shrink: 0;

      @include media(md) {
        width: 280px;
      }

      @include media(lg) {
        width: 294px;
      }

      @include media(xl) {
        width: 254px;
      }
    }

    .ui-header-courses__list {
      @include ulres;

      grid-column: 1/-1;
      display: flex;
    }

    .ui-header-courses__title {
      margin-bottom: 0;
    }

    .ui-header-courses__slider-controls {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: 8px;

      @include media(xl) {
        margin-right: 14px;
      }
    }
  }
</style>
