export const state = () => ({
  headerDirections: []
})

export const mutations = {
  setHeaderDirections(state, directions) {
    state.headerDirections = directions
  }
}

export const actions = {
  async fetchHeaderDirections({ commit, state }) {
    if (state.headerDirections.length) {
      return
    }

    await this.$api.headerDirections.get()
      .then(({ data: res }) => {
        if (res.data.length === 0) {
          this.$logger.log(new Error('[Business directions]: No business directions found in request'))
        }

        commit('setHeaderDirections', res.data)
      })
      .catch((e) => {
        this.$logger.log(e, {
          tags: {
            isNeedAlert: true
          }
        })
      })
  }
}

export const getters = {
  getHeaderDirections({ headerDirections }) {
    return headerDirections
  }
}
