<template>
  <ul class="ui-header-dropdown-mobile">
    <li
      v-for="(item, i) in dropdown"
      :key="i"
      class="ui-header-dropdown-mobile__item"
      :class="{'ui-header-dropdown-mobile__item--split-line': item.splitLine}"
    >
      <UiListItemLink
        v-if="isRoutesInside && item.to"
        :title="item.title"
        :to="item.to"
        :is-active="item.to === $route.path"
        theme="main"
        size="small"
        class="ui-header-nav-spoiler__link"
        @click.native="linkClickHandler(item)"
      />

      <UiListItemLink
        v-else
        :title="item.title"
        :href="item.href"
        theme="main"
        size="small"
        target="_blank"
        class="ui-header-nav-spoiler__link"
        @click.native="linkClickHandler(item)"
      />
    </li>
  </ul>
</template>

<script>
  import UiListItemLink from '@skillbox/front-box/dist/Items/UiListItemLink/UiListItemLink'

  export default {
    name: 'UiHeaderDropdownMobile',
    components: {
      UiListItemLink
    },
    props: {
      dropdown: {
        type: Array,
        required: true
      },
      isRoutesInside: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      linkClickHandler(item) {
        this.$analytics && this.$analytics.push('header-second-level-click', {
          buttonName: item.title,
          dropdownItemName: item.analytics.title
        })

        this.$emit('link-click')
      }
    }
  }
</script>

<style lang="scss">
  .ui-header#ui-header {
    .ui-header-dropdown-mobile {
      @include ulres;
    }

    .ui-header-dropdown-mobile__item {
      margin-bottom: 4px;

      &--split-line {
        position: relative;
        padding-bottom: 12px;
        margin-bottom: 12px;

        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          margin-right: 16px;
          margin-left: 16px;
          width: calc(100% - 32px);
          height: 1px;

          background: var(--stroke-main-color);
          content: '';
        }
      }
    }
  }
</style>
