module.exports = {
  skillbox: {
    logo: true,
    directions: true,
    about: true,
    webinars: false
  },
  live: {
    logo: false,
    directions: false,
    about: false,
    webinars: true
  },
  other: {}
}
